<script lang="ts" setup>
/**
 * Basic password input field with an additional unmask button to display the current input as clear text.
 *
 * NOTE: This feature should have been as easy as switching the type attribute from password to text.
 * Simple browser tests show that this should work. For some reason, we needed a proxy value
 * setup to make it work here. Needs further investigation.!
 */
defineOptions({
  inheritAttrs: false,
});

const [modelValue] = defineModel({ type: String, required: true, default: '' });

const emits = defineEmits<{
  'update:modelValue': [value: string];
}>();

const props = defineProps<{
  errors?: string | string[];
}>();

const type = ref('password') as Ref<'password' | 'text'>;
const proxy = ref(modelValue.value);

watch(proxy, (value) => {
  if (!value) {
    type.value = 'password';
  }
  emits('update:modelValue', proxy.value);
});

function onUnmask() {
  type.value = type.value === 'password' ? 'text' : 'password';
}

const hasErrors = computed(() => {
  if (Array.isArray(props.errors)) {
    return props.errors.length > 0;
  }

  return props.errors;
});
</script>

<template>
  <slot name="label" />
  <div>
    <input :type="type" v-model="proxy" v-bind="$attrs" :class="['password', { 'password--error': hasErrors, 'password--success': !hasErrors && proxy }]" />
    <button type="button" @click="onUnmask" v-if="proxy?.length > 0" tabindex="-1">
      <Icon name="bi:eye-fill" v-if="type === 'password'" />
      <Icon name="bi:eye-slash-fill" v-else />
    </button>
  </div>
  <tw-form-error v-if="hasErrors">
    <slot name="errors" v-bind="{ errors }">
      <ul v-if="Array.isArray(errors)">
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
      <div v-else>{{ errors }}</div>
    </slot>
  </tw-form-error>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-form.scss';

div {
  position: relative;
}

.password {
  padding-right: 3rem;
  @include tw-input-string;
}

button {
  top: 0;
  right: 0;
  width: 3rem;
  height: 100%;
  border: none;
  position: absolute;
  background-color: transparent;
}

.icon {
  width: 1rem;
  height: 1rem;
  color: $black;
}
</style>
